<template>
  <div class="rounded-lg shadow overflow-hidden bg-white">
    <button @click="onOpenDetail">
      <img
        :style="$store.nftBgStyle(source)"
        class="w-full"
        :src="source.image"
        v-if="source.image.length > 0"
      />
      <div class="m-2">
        <div class="text-left font-bold">{{ source.title }}</div>
        <div class="flex justify-between">
          <div class="flex">
            <img :src="$store.getEggImage(source, 1)" :class="eggClass" />
            <img :src="$store.getEggImage(source, 2)" :class="eggClass" />
            <img :src="$store.getEggImage(source, 3)" :class="eggClass" />
            <img :src="$store.getEggImage(source, 4)" :class="eggClass" />
            <img :src="$store.getEggImage(source, 5)" :class="eggClass" />
          </div>
          <div></div>
        </div>
        <div class="text-left text-sm">
          {{ $store.getStatusText(source) }}
        </div>
        <div class="line-clamp-1 text-left text-sm text-gray-500">
          {{ source.description }}
        </div>
      </div>
    </button>
  </div>
</template>

<script>
// import IconText from "@/icons/IconText.vue";
// import IconEdit from "@/icons/IconEdit.vue";
// import IconConfirm from "@/icons/IconConfirm.vue";
// import IconCancel from "@/icons/IconCancel.vue";

export default {
  // components: { IconText, IconEdit, IconConfirm, IconCancel },
  props: {
    source: Object,
  },
  data() {
    return {
      eggClass: "w-6 h-6",
      loaded: false,
      uid: -1,
      edit_uid: -1,
      edit_input: "",
    };
  },
  mounted() {
    this.loaded = true;
    this.uid = this.source.uid;
    // console.log("mounted", this.source);
  },
  computed: {
    statusText: (state) => {
      if (state.source.status == 1) {
        const end = state.source.date_end;
        if (state.$store.isFuture(end)) {
          const ago = state.source.date_end.fromNow(true);
          return `부화중, ${ago}`;
        }
      } else if (state.source.status == 3) {
        const grade = state.source.grade;
        const end = state.source.date_end;
        if (state.$store.isFuture(end)) {
          const ago = state.source.date_end.fromNow(true);
          return `생산중, ${ago}, ${grade}알 남음`;
        }
      }
      return "";
    },
    canBuyEgg: (state) => {
      return state.source.status === 0;
    },
    canHatch: (state) => {
      if (state.source.status != 1) return false;
      const end = state.source.date_end;
      return state.$store.isFuture(end) === false;
    },
    canChoice: (state) => {
      return state.source.status == 2;
    },
  },
  methods: {
    onOpenDetail() {
      console.log("onOpenDetail", this.source.uid);
      // this.$store.commitRoot("open_detail", this.source.uid);
      this.$emit("onDetail");
    },
    async onChoice(ok) {
      const uid = this.source.uid;
      console.log("onChoice", uid, ok);

      const res = await this.$store.postAsync({
        api: "choice_dak",
        uid,
        ok,
      });

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }

      this.$store.commitRoot("set_nft", res.data);
    },
  },
};
</script>
