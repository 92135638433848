<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :class="classVal"
      :fill="fillVal"
      :stroke="strokeVal"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    classVal: {
      type: String,
      default: "h-6 w-6",
    },
    fillVal: {
      type: String,
      default: "none",
    },
    strokeVal: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
