<template>
  <div v-if="!loading" class="space-y-10">
    <div class="space-y-4" v-if="$store.state.login !== null">
      <div class="text-2xl text-black dark:text-white">{{ $t("#INFO") }}</div>
      <div class="grid grid-cols-3 sm:grid-cols-4 gap-2">
        <div class="tw-farm-mini-card" v-if="$store.DISPLAY_URL.length > 0">
          <div class="text-xs">URL:</div>
          <div
            class="w-full text-right"
            style="text-overflow: ellipsis; overflow: hidden"
          >
            {{ $store.DISPLAY_URL }}
          </div>
        </div>
        <div class="tw-farm-mini-card" v-if="$store.DISPLAY_URL.length > 0">
          <div class="text-xs">{{ $t("#WALLET") }}:</div>
          <div
            class="w-full text-right"
            style="text-overflow: ellipsis; overflow: hidden"
          >
            {{ addr }}
          </div>
        </div>
        <div class="tw-farm-mini-card">
          <div class="text-xs">{{ $t("#HATCH_TIME") }}:</div>
          <div>{{ $store.getters.hatch_time_text }}</div>
        </div>
        <div class="tw-farm-mini-card">
          <div class="text-xs">{{ $t("#HATCH_FEE") }}:</div>
          <div>{{ $store.getters.fee_text }}</div>
        </div>
      </div>
    </div>

    <div v-if="nft_count <= 0">
      <div class="p-4 rounded-xl text-2xl text-red-600 dark:text-red-400">
        {{ $t("#ERR_NEED_PD_NFT") }}
      </div>
    </div>
    <div v-else class="space-y-4">
      <div class="text-2xl text-black dark:text-white">
        {{ $t("#OWNED_LIST") }}
      </div>
      <div v-if="detail_source != null">
        <PotdakDetail
          :source="detail_source"
          :contract="contract"
          :addr="addr"
          @onCloseDetail="onCloseDetail"
        />
      </div>
      <!-- <div>보유중인 심는닭: {{ nft_count }} 마리</div> -->
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
        <div v-for="data in this.$store.getters.nft_list" :key="data.uid">
          <PotdakItem :source="data" @onDetail="onDetail(data)" />
        </div>
      </div>
    </div>
    <!-- <PotdakItem :abc="abc" @changeAbc="onChangeAbc" /> -->
  </div>
  <div v-else class="text-2xl text-black dark:text-white min-h-screen">
    <div v-html="$t(loading_msg)"></div>
  </div>
</template>

<script>
// import ContractBaobab from "@/abi/PlantosyDak-main-v83.json";
// import ContractMain from "@/abi/PlantosyDak-main-v83.json";
import ContractMain from "@/abi/PlantosyDakV3.json";
import PotdakItem from "@/components/PotdakItem.vue";
import PotdakDetail from "@/components/PotdakDetail.vue";

export default {
  components: { PotdakItem, PotdakDetail },
  data() {
    return {
      loading: true,
      loading_msg: "#LOADING",
      addr: "",
      nft_count: 0,
      // nft_list: [],
      // loginData: null,
      errorMint: "",
      // fee_text: "?",
      // edit_uid: -1,
      // edit_input: "",
      contract: null,
      detail_source: null,
    };
  },
  unmounted() {
    this.$store.commitRoot("set_enter_name", {});

    this.$store.commitRoot("set_account_change_callback", () => {
      return true;
    });
  },
  async mounted() {
    this.$store.commitRoot("set_ui", this.$route.fullPath);

    // 서버에서 막는지 검사
    let res = await this.$store.postAsyncSimple({
      api: "can_farm_enter",
    });
    if (res.code != 100) {
      // 농장 점검중
      await this.$store.waitAsync(1000);
      this.$store.AlertPost(res);
      this.$router.push("/pdak");
      return;
    }

    // 로그인 안되어 있으면 로그인 시킨다.
    if (!this.$store.state.lv2_is_login) {
      this.$store.state.lv2_show_wallet_login = true;
      do {
        await this.$store.waitAsync(1000);
      } while (this.$store.state.lv2_login_doing);
    }

    // 로그인 검사
    // if (!this.$store.state.lv2_is_login) {
    //   await this.$store.waitAsync(1000);
    //   this.$store.Alert("#ERR_NEED_KAIKAS");
    //   this.$router.push("/pdak");
    //   return;
    // }
    if (!this.$store.state.lv2_is_login) {
      await this.$store.waitAsync(1000);
      this.$store.Alert("#ERR_NEED_KAIKAS");
      this.$router.push("/pdak");
      return;
    }

    const wallet = this.$store.state.lv2_login_data.wallet;
    // console.log("wallet", wallet);

    const networkId = process.env.VUE_APP_KLAYTN_NETWORK_ID;
    if (`${wallet.networkVersion}` != `${networkId}`) {
      console.log("networkId fail #1", wallet.networkVersion, networkId);
      this.$store.Alert("#ERR_CONTRACT_NETWORK_ID");
      this.$router.push("/pdak");
      return;
    }

    // 로그인이 긑나면 계정 변경 감시
    this.$store.commitRoot("set_account_change_callback", () => {
      this.$store.Alert("#ERR_WALLET_CHANGED");
      this.$router.push("/pdak");
      return true;
    });

    // const klaytn = window.klaytn;
    // const old_addr = klaytn.selectedAddress;
    // const fail_call = () => {
    //   this.$store.Alert("#ERR_WALLET_CHANGED");
    //   this.$router.push("/pdak");
    // };

    // this.$store.commitRoot("set_enter_name", {
    //   target: null,
    //   fail: "/pdak",
    //   onChangedAccount: () => {
    //     console.log("onChangedAccount", old_addr, klaytn.selectedAddress);
    //     if (old_addr === undefined) return;
    //     if (old_addr == klaytn.selectedAddress) return;
    //     setTimeout(fail_call, 1000);
    //   },
    // });

    // let accounts = undefined;
    // let old_loading = this.loading_msg;
    // try {
    //   this.loading_msg = "#LOADING_WAIT_WALLET";
    //   accounts = await klaytn.enable();
    // } catch (err) {
    //   err;
    // }
    // this.loading_msg = old_loading;

    // if (!Array.isArray(accounts) || accounts.length < 1) {
    //   await this.$store.waitAsync(1000);
    //   this.$store.Alert("#ERR_NETWORK");
    //   this.$router.push("/pdak");
    //   return;
    // }

    // // 항상 다시 로드한다.
    // const caver = new Caver(klaytn);
    // let contract = null;
    // {
    //   let constractAbi = ContractMain;
    //   let networkId = 8217;
    //   if (process.env.VUE_APP_MODE != "PUBLIC") {
    //     networkId = 1001;
    //   }

    //   console.log("networkId check", klaytn.networkVersion, networkId);
    //   if (klaytn.networkVersion !== networkId) {
    //     await this.$store.waitAsync(1000);
    //     this.$store.Alert("#ERR_CONTRACT_NETWORK_ID");
    //     this.$router.push("/pdak");
    //     return;
    //   }

    //   const deplyedNetwork = constractAbi.networks[networkId];
    //   if (deplyedNetwork === undefined) {
    //     await this.$store.waitAsync(1000);
    //     this.$router.push("/pdak");
    //     this.$store.Alert("#ERR_CONTRACT_NETWORK_ID");
    //     return;
    //   }

    //   contract = new caver.klay.Contract(
    //     constractAbi.abi,
    //     deplyedNetwork.address
    //   );
    //   this.$store.commitRoot("set_pd_contract", contract);
    // }

    // console.log("wait_time start");
    // let wait_time = 0;
    // while (wait_time < 3000) {
    //   await await this.$store.waitAsync(300);
    //   wait_time += 300;

    //   if (typeof klaytn.selectedAddress == "string") {
    //     break;
    //   }
    // }
    // console.log("wait_time end");

    // if (typeof klaytn.selectedAddress != "string") {
    //   await this.$store.waitAsync(1000);
    //   this.$router.push("/pdak");
    //   this.$store.Alert("#ERR_NETWORK");
    //   return;
    // }

    // const addr = klaytn.selectedAddress;
    // this.addr = addr;
    // console.log("addr", addr);

    const addr = this.$store.state.lv2_login_data.addr;
    this.addr = addr;

    const contract = this.$store.get_contract(ContractMain);
    this.contract = contract;

    let ret = await contract.methods.balanceOf(addr).call();
    let count = Number(ret);
    this.nft_count = count;
    console.log("balanceOf:", count);

    const nft_inventory_list = [];
    for (let i = 0; i < count; i++) {
      // console.log("farm #3", i);

      let pdId = await contract.methods
        .tokenOfOwnerByIndex(this.addr, i)
        .call();
      // console.log("farm #4", pdId);

      let peId = await contract.methods.getPeIdByPdId(pdId).call();

      // let a = await contract.methods.getGrade(pdId).call();
      // console.log("getGrade", pdId, a);

      let data = { uid: Number(pdId) };
      if (peId != 0) {
        data.pe_uid = Number(peId);
      }

      nft_inventory_list.push(data);
    }

    console.log("postAsync  login");
    // console.log("nft_inventory_list", nft_inventory_list);
    res = await this.$store.postAsync({
      api: "login",
      addr,
      nft_inventory_list,
    });
    // console.log("login", res);
    if (res.code != 100) {
      await this.$store.waitAsync(1000);
      this.$store.AlertPost(res);
      this.$router.push("/pdak");
      return;
    }

    this.$store.commit("set_login", {
      gas_limit: res.gas_limit,
      fee: res.fee,
      fee_text: res.ree_text,
      hatch_time: res.hatch_time,
      addr,
    });

    console.log("nft_list start");
    const nft_list = [];
    for (let i in res.nft_data_list) {
      const nft_data = res.nft_data_list[i];
      nft_list.push(nft_data);
    }
    console.log("nft_list set_nft_list", nft_list);
    this.$store.commitRoot("set_nft_list", nft_list);
    // console.log("this.nft_data_list", this.nft_list);

    setTimeout(() => {
      console.log("mount end");
      this.loading = false;
    }, 1000);
  },
  methods: {
    onDetail(data) {
      console.log("onDetail", data);
      this.detail_source = data;
    },
    onCloseDetail() {
      console.log("onCloseDetail", this.detail_sourc);
      this.detail_source = null;
    },
  },
};
</script>
