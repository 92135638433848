<template>
  <div>
    <div
      id="mymodal"
      class="bg-black bg-opacity-50 absolute h-full w-full inset-0 flex justify-center items-center"
    >
      <div class="rounded-lg bg-white w-96 p-2">
        <div class="relative flex justify-end">
          <button @click="onBack()" class="absolute -top-7 -right-7">
            <IconCancel
              :fillVal="closeFill"
              :classVal="closeClass"
              :strokeVal="closeStroke"
            />
          </button>
        </div>

        <div class="p-2">
          <div class="font-bold text-xl">{{ source.title }}</div>

          <div class="pt-4">
            <div
              v-if="!edit_desc"
              class="flex justify-between items-center border-b-2 border-gray-400 hover:border-gray-600"
            >
              <div v-html="md(source.description)"></div>
              <button @click="onEditStart"><IconEdit /></button>
            </div>
            <div v-else class="flex justify-between items-center space-x-1">
              <textarea
                class="rounded-lg border-2 border-black w-full px-2"
                v-model="edit_input"
                rows="5"
              />
              <div class="flex items-center">
                <button @click="onEditEnd(true)">
                  <icon-confirm
                    :classVal="editClass"
                    :strokeVal="strokeGreen"
                  />
                </button>
                <button @click="onEditEnd(false)">
                  <icon-cancel :classVal="editClass" :strokeVal="strokeRed" />
                </button>
              </div>
            </div>

            <div class="pt-4">
              <div class="grid grid-cols-2 gap-2">
                <div>
                  <div class="font-bold">{{ $t("#FUNCTION") }}</div>
                  <div class="space-y-1">
                    <button
                      :class="$store.canHatchEggClass(source)"
                      @click="onHatchEgg()"
                      @mouseover="onTip(idHatch)"
                      @mouseleave="onTip()"
                    >
                      {{ $t("#HATCH_COMPLETE") }}
                    </button>
                    <button
                      v-if="false"
                      :class="$store.canTakeDakClass(source)"
                      @click="onTakeDak(true)"
                      @mouseover="onTip(idTakeDak)"
                      @mouseleave="onTip()"
                    >
                      {{ $t("#CONFIRM") }}
                    </button>
                    <button
                      v-if="false"
                      :class="$store.canTakeDakClass(source)"
                      @click="onTakeDak(false)"
                      @mouseover="onTip(idRevert)"
                      @mouseleave="onTip()"
                    >
                      {{ $t("#BACK_TO_EGG") }}
                    </button>
                    <button
                      class="flex items-center space-x-2"
                      :class="$store.canTakeEggClass(source)"
                      @click="onTakeEgg()"
                      @mouseover="onTip(idTakeEgg)"
                      @mouseleave="onTip()"
                    >
                      <div>{{ $t("#TAKE_EGG") }}</div>
                      <div class="text-xs">
                        ({{ $t("#FEE") }}: {{ $store.getters.fee_text }})
                      </div>
                    </button>
                  </div>
                  <div
                    v-if="tipMessage.length > 0"
                    class="absolute w-64 rounded-lg shadow-lg p-2 bg-gray-600 text-white"
                  >
                    {{ tipMessage }}
                  </div>
                </div>
                <div>
                  <div class="font-bold">{{ $t("#BG_SELECT") }}</div>
                  <div class="flex justify-between items-center w-full">
                    <div class="w-12"></div>
                    <img
                      :src="source.image"
                      :style="bgStyle"
                      alt="nft image"
                      class="w-12 h-12 rounded-lg border-2 border-black"
                    />
                    <div class="flex w-12">
                      <button v-if="bgOXBtnShow" @click="onBgConfirm(true)">
                        <IconConfirm
                          :classVal="bgOXBtnClass"
                          :strokeVal="strokeGreen"
                        />
                      </button>
                      <button v-if="bgOXBtnShow" @click="onBgConfirm(false)">
                        <IconCancel
                          :classVal="bgOXBtnClass"
                          :strokeVal="strokeRed"
                        />
                      </button>
                    </div>
                  </div>

                  <div class="mt-3 grid grid-cols-6 gap-1">
                    <button @click="onBg('')" class="card-bg">X</button>
                    <button
                      @click="onBg('ffffff')"
                      class="card-bg bg-white"
                    ></button>
                    <button
                      @click="onBg('000000')"
                      class="card-bg bg-black"
                    ></button>
                    <button
                      @click="onBg('DC2626')"
                      class="card-bg bg-red-600"
                    ></button>
                    <button
                      @click="onBg('F87171')"
                      class="card-bg bg-red-400"
                    ></button>
                    <button
                      @click="onBg('FECACA')"
                      class="card-bg bg-red-200"
                    ></button>
                    <button
                      @click="onBg('059669')"
                      class="card-bg bg-green-600"
                    ></button>
                    <button
                      @click="onBg('34D399')"
                      class="card-bg bg-green-400"
                    ></button>
                    <button
                      @click="onBg('A7F3D0')"
                      class="card-bg bg-green-200"
                    ></button>
                    <button
                      @click="onBg('2563EB')"
                      class="card-bg bg-blue-600"
                    ></button>
                    <button
                      @click="onBg('60A5FA')"
                      class="card-bg bg-blue-400"
                    ></button>
                    <button
                      @click="onBg('BFDBFE')"
                      class="card-bg bg-blue-200"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-4">
              <div class="font-bold">{{ $t("#EXTERNAL_LINK") }}</div>
              <div class="flex justify-start">
                <a :href="hrefOpensea" target="_blank"
                  ><img
                    class="h-8 px-2 py-1 shadow rounded-lg bg-gray-200"
                    src="/img/opensea/openSea-full-Logo-dark-small.png"
                /></a>
                <a :href="hrefViewer" target="_blank"
                  ><div
                    class="w-24 h-8 py-1 ml-2 shadow rounded-lg bg-gray-500 flex justify-center items-center"
                  >
                    <div class="mt-1 font-bold text-sm text-white">VIEWER</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// popup 설명
// https://github.com/johnkomarnicki/vue-3-modal/tree/master/app
// 등장 에니메이션도 있음
import IconCancel from "@/icons/IconCancel.vue";
import IconConfirm from "@/icons/IconConfirm.vue";
import IconEdit from "@/icons/IconEdit.vue";
export default {
  components: { IconCancel, IconConfirm, IconEdit },
  props: {
    source: Object,
    contract: Object,
    addr: String,
  },
  data() {
    return {
      closeFill: "black",
      closeClass: "w-10 h-10",
      closeStroke: "white",
      strokeGreen: "green",
      strokeRed: "red",
      editClass: "h-8",
      //
      edit_desc: false,
      edit_input: "",
      //
      tipMessage: "",
      idHatch: "idHatch",
      idTakeDak: "idTakeDak",
      idRevert: "idRevert",
      idTakeEgg: "idTakeEgg",
      //
      bg: "",
      bgOXBtnClass: "w-6 h-6",
      bgOXBtnShow: false,
    };
  },
  mounted() {
    console.log("detail mounted", this.source);
    this.bg = this.source.bg;
  },
  computed: {
    hrefOpensea() {
      return this.$store.get_opensea_url(this.source.uid);
    },
    hrefViewer() {
      return `/pdview/${this.source.uid}`;
    },
    bgStyle(state) {
      const ret = state.$store.nftBgStyle({ bg: state.bg });
      console.log("bgStyle", state.bg, ret);
      return ret;
    },
  },
  methods: {
    onBack() {
      // this.$store.commitRoot("close_detail");
      this.$emit("onCloseDetail");
    },
    onEditStart() {
      this.edit_desc = true;
      this.edit_input = this.source.description;
      console.log("onEditStart", this.edit_input);
    },
    async onEditEnd(confirm) {
      console.log("onEditEnd", confirm, this.edit_input);
      this.edit_desc = false;

      if (!confirm) {
        return;
      }

      // console.log("onEditEnd 1");
      // const contract = this.$store.getters.pd_contract;
      const contract = this.contract;
      // const addr = this.$store.getters.login.addr;
      const addr = this.addr;
      const owner_addr = await contract.methods.ownerOf(this.source.uid).call();
      if (owner_addr.toLowerCase() != addr.toLowerCase()) {
        console.log("onEditEnd fail, ownerOf", owner_addr, addr);
        return;
      }

      const res = await this.$store.postAsync({
        api: "edit_desc",
        uid: this.source.uid,
        input: this.edit_input,
      });

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }

      this.$store.commitRoot("set_nft", res.data);
      // this.onBack();
    },
    onBg(rgb) {
      // console.log("onBG", rgb);
      this.bg = rgb;
      this.bgOXBtnShow = this.bg != this.source.bg;
    },
    async onBgConfirm(confirm) {
      console.log("onBgConfirm", confirm);
      if (!confirm) {
        this.bg = this.source.bg;
        return;
      }

      // const contract = this.$store.getters.pd_contract;
      const contract = this.contract;
      // const addr = this.$store.getters.login.addr;

      console.log("onBgConfirm #1", contract);
      const owner_addr = await contract.methods.ownerOf(this.source.uid).call();
      console.log("onBgConfirm #1", owner_addr);
      const addr = this.addr;
      console.log("onBgConfirm #2", addr);
      if (owner_addr.toLowerCase() != addr.toLowerCase()) {
        console.log("onBgConfirm fail, ownerOf", owner_addr, addr);
        return;
      }

      const res = await this.$store.postAsync({
        api: "edit_bg",
        uid: this.source.uid,
        bg: this.bg,
      });

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }

      this.$store.commitRoot("set_nft", res.data);
      this.bgOXBtnShow = false;
    },
    onTip(id) {
      if (id == this.idHatch) {
        this.tipMessage = this.$t("#TIP_HATCH_EGG");
      } else if (id == this.idTakeDak) {
        this.tipMessage = this.$t("#TIP_CONFIRM_DAK");
      } else if (id == this.idRevert) {
        this.tipMessage = this.$t("#TIP_BACK_TO_EGG");
      } else if (id == this.idTakeEgg) {
        this.tipMessage = this.$t("#TIP_TAKE_EGG");
      } else {
        this.tipMessage = "";
      }
    },
    async onHatchEgg() {
      console.log("onHatchEgg");

      if (!this.$store.canHatchEgg(this.source)) {
        this.$store.Alert("#ERR_PD_EGG_NEED_TIME");
        return;
      }

      const res = await this.$store.postAsync({
        api: "hatch_egg",
        uid: this.source.uid,
      });
      // console.log("hatch_egg res", res);

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }

      this.$store.commitRoot("set_nft", res.data);
      this.onBack();
    },
    async onTakeDak(ok) {
      console.log("onTakeDak", ok);

      if (!this.$store.canTakeDak(this.source)) {
        this.$store.Alert("#ERR_PD_TAKE_DAK_STATUE");
        return;
      }

      const res = await this.$store.postAsync({
        api: "choice_dak",
        uid: this.source.uid,
        ok,
      });

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }

      this.$store.commitRoot("set_nft", res.data);
      this.onBack();
    },
    async onTakeEgg() {
      console.log("onTakeEgg");

      if (!this.$store.canTakeEgg(this.source)) {
        this.$store.Alert("#TIP_TAKE_EGG");
        return;
      }
      // console.log("onTakeEgg canTakeEgg", this.source);

      const egg_uid = this.source.egg + 1;
      const parent_uid = this.source.uid;
      const klaytn = window.klaytn;
      if (klaytn === null || klaytn.selectedAddress === undefined) {
        console.log("no selectedAddress");
        this.$router.push("/pdak");
        return;
      }

      let ret;

      // const addr = klaytn.selectedAddress;
      // const account = await klaytn.enable();
      // const addr = account[0];
      const addr = this.addr;

      // const contract = this.$store.getters.pd_contract;
      const contract = this.contract;
      ret = await contract.methods.balanceOf(addr).call();
      const balance = Number(ret);
      ret = await contract.methods.getGrade(parent_uid).call();
      const grade = Number(ret);
      ret = await contract.methods.getPdIdByPeId(parent_uid, egg_uid).call();
      const pdid = Number(ret);

      const gas_limit = this.$store.getters.login.gas_limit;
      const fee = this.$store.getters.login.fee;
      console.log("onTakeEgg can_mint before", gas_limit, fee);
      let res = await this.$store.postAsync({
        api: "can_mint",
        parent_uid,
        egg_uid,
        addr,
        balance,
        gas_limit,
        fee,
        grade,
        pdid,
      });
      console.log("can_mint res", res);

      if (res.code != 100) {
        this.$store.AlertPost(res);
        return;
      }

      try {
        console.log("mintPE try", parent_uid, egg_uid);
        // await contract.methods.mintPE(parent_uid, egg_uid).send({
        await contract.methods.mintByEgg(parent_uid, egg_uid).send({
          from: addr,
          gas: gas_limit,
          value: fee,
        });

        this.$store.waitAsync(3000);

        console.log("mintPotDakEgg ok");
      } catch (err) {
        console.log("mintPotDakEgg err", err);

        this.$store.Alert("#ERR_PD_MINT_FAIL");

        const errShort = err.message.substring(0, 40);
        this.$store.sendLog(["mintPE err", errShort]);
        return;
      }

      // 민팅이 성공했다면 마지막 소유 NFT 주소르 보내서 팟을 받는다.
      const balance2 = await contract.methods.balanceOf(addr).call();
      if (balance >= balance2) {
        // 잔고가 늘지 않으면 이상하다.
        this.$store.Alert("#ERR_PD_MINT_AFTER");
        return;
      }

      const new_uid = await contract.methods
        .getPdIdByPeId(parent_uid, egg_uid)
        .call();

      res = await this.$store.postAsync({
        api: "take_egg",
        uid: new_uid,
        parent_uid,
        egg_uid,
      });
      console.log("take_egg res", res);

      if (res.code != 100) {
        // this.$store.Alert(
        //   `민팅 처리에 문제가 발생하였습니다. 재접속 해주세요. ERROR: take_egg api fail.`
        // );
        this.$store.AlertPost(res);
        return;
      }

      //gunil
      this.$store.commitRoot("set_nft", res.data);
      this.$store.commitRoot("add_nft", res.new_pd);
      this.onBack();
    },
  },
};
</script>

<style scoped>
#mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-bg {
  @apply w-6 h-6 rounded border-2 border-black;
}
</style>
